<template>
  <div>
    <footer>
      <div id="footer_top">
        <h3>Retrouvez-moi sur les réseaux sociaux</h3>
        <div id="svg_list_top">
          <svg xmlns="http://www.w3.org/2000/svg" width="42.012" height="78.442" viewBox="0 0 42.012 78.442">
            <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f"
                  d="M40.869,44.124l2.179-14.2H29.426V20.715c0-3.884,1.9-7.67,8-7.67h6.193V.959A75.518,75.518,0,0,0,32.629,0c-11.218,0-18.55,6.8-18.55,19.108V29.927H1.609v14.2h12.47V78.442H29.426V44.124Z"
                  transform="translate(-1.609)" fill="#7934cb"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="78.443" height="78.443" viewBox="0 0 78.443 78.443">
            <path id="Icon_metro-instagram" data-name="Icon metro-instagram"
                  d="M67.991,1.928h-52.4A13.061,13.061,0,0,0,2.571,14.951v52.4A13.061,13.061,0,0,0,15.593,80.371h52.4A13.061,13.061,0,0,0,81.013,67.348v-52.4A13.061,13.061,0,0,0,67.991,1.928ZM27.682,36.247H55.9a14.938,14.938,0,1,1-28.221,0Zm43.526,0V65.663a4.917,4.917,0,0,1-4.9,4.9H17.279a4.917,4.917,0,0,1-4.9-4.9V36.247h7.669a22.292,22.292,0,1,0,43.495,0l7.669,0Zm0-17.163a2.459,2.459,0,0,1-2.451,2.451h-4.9A2.459,2.459,0,0,1,61.4,19.087v-4.9a2.459,2.459,0,0,1,2.451-2.451h4.9a2.459,2.459,0,0,1,2.451,2.451Z"
                  transform="translate(-2.571 -1.928)" fill="#7934cb"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="68.148" height="78.442" viewBox="0 0 68.148 78.442">
            <path id="Icon_simple-tiktok" data-name="Icon simple-tiktok"
                  d="M38.16.065C42.442,0,46.691.033,50.94,0,51.2,5,53,10.1,56.659,13.629c3.661,3.628,8.825,5.295,13.858,5.85V32.651a34.983,34.983,0,0,1-13.727-3.17,40.382,40.382,0,0,1-5.295-3.04c-.033,9.544.033,19.087-.065,28.6a24.964,24.964,0,0,1-4.412,12.877A24.35,24.35,0,0,1,27.7,78.408a23.832,23.832,0,0,1-13.335-3.366A24.647,24.647,0,0,1,2.437,56.379c-.065-1.634-.1-3.268-.033-4.87A24.605,24.605,0,0,1,30.937,29.677c.065,4.837-.131,9.674-.131,14.512a11.214,11.214,0,0,0-14.315,6.929c-.686,1.667-.49,3.5-.458,5.262.784,5.36,5.948,9.87,11.439,9.38A10.98,10.98,0,0,0,36.526,60.5a7.541,7.541,0,0,0,1.34-3.464c.327-5.85.2-11.668.229-17.518.033-13.172-.033-26.31.065-39.449Z"
                  transform="translate(-2.369)" fill="#7934cb"/>
          </svg>
        </div>

        <p>Suivez toute l'actualité de mes coaching !</p>
      </div>

      <div id="footer_bottom">

        <div>
        <h4>À Propos</h4>
        <ul>
          <li>
            <router-link to="/mentionslegales">Mentions Légales</router-link>
          </li>
          <li>
            <router-link to="politiqueconfidentialite">Politique de confidentialité</router-link>
          </li>
        </ul>
        </div>

        <div>
          <h4>Contact</h4>

          <ul>
            <li>
              <p>Téléphone : <a href="">07 67 54 89 08</a></p>
            </li>
            <li>
              <p>Email : <a href="">al-grandjean@gmail.com</a></p>
            </li>
          </ul>
        </div>
        <div>
          <h4>Réseaux Sociaux</h4>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="42.012" height="78.442" viewBox="0 0 42.012 78.442">
              <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f"
                    d="M40.869,44.124l2.179-14.2H29.426V20.715c0-3.884,1.9-7.67,8-7.67h6.193V.959A75.518,75.518,0,0,0,32.629,0c-11.218,0-18.55,6.8-18.55,19.108V29.927H1.609v14.2h12.47V78.442H29.426V44.124Z"
                    transform="translate(-1.609)" fill="#7934cb"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="78.443" height="78.443" viewBox="0 0 78.443 78.443">
              <path id="Icon_metro-instagram" data-name="Icon metro-instagram"
                    d="M67.991,1.928h-52.4A13.061,13.061,0,0,0,2.571,14.951v52.4A13.061,13.061,0,0,0,15.593,80.371h52.4A13.061,13.061,0,0,0,81.013,67.348v-52.4A13.061,13.061,0,0,0,67.991,1.928ZM27.682,36.247H55.9a14.938,14.938,0,1,1-28.221,0Zm43.526,0V65.663a4.917,4.917,0,0,1-4.9,4.9H17.279a4.917,4.917,0,0,1-4.9-4.9V36.247h7.669a22.292,22.292,0,1,0,43.495,0l7.669,0Zm0-17.163a2.459,2.459,0,0,1-2.451,2.451h-4.9A2.459,2.459,0,0,1,61.4,19.087v-4.9a2.459,2.459,0,0,1,2.451-2.451h4.9a2.459,2.459,0,0,1,2.451,2.451Z"
                    transform="translate(-2.571 -1.928)" fill="#7934cb"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="68.148" height="78.442" viewBox="0 0 68.148 78.442">
              <path id="Icon_simple-tiktok" data-name="Icon simple-tiktok"
                    d="M38.16.065C42.442,0,46.691.033,50.94,0,51.2,5,53,10.1,56.659,13.629c3.661,3.628,8.825,5.295,13.858,5.85V32.651a34.983,34.983,0,0,1-13.727-3.17,40.382,40.382,0,0,1-5.295-3.04c-.033,9.544.033,19.087-.065,28.6a24.964,24.964,0,0,1-4.412,12.877A24.35,24.35,0,0,1,27.7,78.408a23.832,23.832,0,0,1-13.335-3.366A24.647,24.647,0,0,1,2.437,56.379c-.065-1.634-.1-3.268-.033-4.87A24.605,24.605,0,0,1,30.937,29.677c.065,4.837-.131,9.674-.131,14.512a11.214,11.214,0,0,0-14.315,6.929c-.686,1.667-.49,3.5-.458,5.262.784,5.36,5.948,9.87,11.439,9.38A10.98,10.98,0,0,0,36.526,60.5a7.541,7.541,0,0,0,1.34-3.464c.327-5.85.2-11.668.229-17.518.033-13.172-.033-26.31.065-39.449Z"
                    transform="translate(-2.369)" fill="#7934cb"/>
            </svg>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: 'FooterPage'
}
</script>
<style lang="less" scoped>

@media (max-width: 1120px) {
  footer {
    width: 100%;

    & div#footer_top {
      display: flex;
      flex-direction: column;
      width: 70%;

      & p , div#svg_list_top {
        margin-top: 30px;
      }
    }
    & div#footer_bottom {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > div {
        width: 230px;
        margin-bottom: 40px;
      }
    }
  }
}

footer {
  max-width: 1320px;

  font-family: 'Urbanist', sans-serif;
  font-weight: normal;

  & #footer_top {
    max-width:  calc(1016px - 2*40px)  ;
    margin: 80px auto;
    padding: 20px 40px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 1px solid var(--blue);
    background: white;
    box-shadow: 0 0 5px #00000050;

    & h3 {
      display: block;
      max-width: 350px;

      font-family: 'Josefin Sans', sans-serif;
      font-weight: bolder;
      font-size: 40px;
      color: var(--gray);
    }

    & #svg_list_top {
      display: inline-block;
      & svg {
        margin: 0 10px;
        max-height: 60px;
      }
    }





    & p {
      max-width: 240px;
      font-weight: lighter;
      font-size: 20px;

    }

  }

  & #footer_bottom {


    color: var(--gray);
    background: var(--light_gray);
    width: calc( 100% - 2*148px) ;
    margin: 0 auto;
    padding: 50px 148px;

    display: flex;
    justify-content: space-between;


    & h4 {
      margin-bottom: 25px;
      font-weight: bold;
      font-size: 22px;

    }

    & li a {
      font-weight: lighter;
      font-size: 18px;
      color: var(--gray);
    }

    & svg {
      max-height: 38px;
    }
  }


  & li {
    list-style-type: none;
  }
}

</style>
